import axios from "axios";

class AuthService {
  async login(userDetails) {
    var result = await axios.get("/udata/users/login_do.json", {
      params: {
        login: userDetails.login,
        password: userDetails.password
      },
      withCredentials: true
    });
    return result.data;
  }

  logout() {
    axios.get("/udata/users/logout.json", {
      withCredentials: true
    });
  }

  async getCurrentUser() {
    var result = await axios.get("/udata/users/get_current_user/.json", {
      withCredentials: true
    });
    return result.data;
  }
}

export default new AuthService();
