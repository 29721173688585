const helpers = {
    // TIME FORMATTING
    formatDateTime(date) {
      if (!date) return '';
      let dateObj = new Date(date);
      return `${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()} ${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
    },
    formatDate(date) {
      let dateObj = new Date(date);
      return dateObj.toLocaleDateString();
    },
    getTimeIntervalInMins(duration) {
      return Math.floor(duration/1000/60);
    },

    // IMAGE FORMATTING
    getImageSrc(img) {
      // дефолтная заглушка
      if (!img) { return require('@/assets/images/pages/logo-k7.svg'); }
      return img.src ? (img.src) : img;
    },

    // PHONE FORMATTING
    formatPhone(str) {
      if (!str) return str;
      let phone = String(str);
      if (phone.length == 10 && /^\d+$/.test(phone)) {
        return "+7 (" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + "-" + phone.substring(6, 10);
      }
      return "Некорректный формат (" + phone + ")";
    },

    // NAME FORMATTING
    getFullUserName(user) {
      let name = [];
      if (user.fname) name.push(user.fname);
      if (user.lname) name.push(user.lname);
      if (user.father_name) name.push(user.father_name);
      return name.join(" ");
    },
    
    // UTILITY - DOWNLOAD DATA AS CSV
    downloadCSV(data, filename) {
      let blob = new Blob([data], { type: 'application/csv' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    }
}

export default helpers