/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import auth from "@/auth/authService.js";

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [

        {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
            path: '',
            component: () => import('./layouts/main/Main.vue'),
            children: [
        // =============================================================================
        // Routes
        // =============================================================================
                {
                    path: '/',
                    redirect: '/dashboard'
                },

                // =============================================================================
                // Главная
                // =============================================================================
                {
                    path: '/dashboard',
                    name: 'dashboard',
                    component: () => import('./views/Dashboard.vue'),
                    meta: {
                        rule: 'employee'
                    }
                },
                // =============================================================================
                // Экран "Смены"
                // =============================================================================
                {
                    path: '/shifts',
                    name: 'shifts',
                    component: () => import('./views/pages/Shifts.vue'),
                    meta: {
                        rule: 'cashier'
                    }
                },
                // =============================================================================
                // Экран "Идеальный отчет"
                // =============================================================================
                {
                    path: '/ideal-report',
                    name: 'ideal-report',
                    component: () => import('./views/pages/IdealReport.vue'),
                    meta: {
                        rule: 'cashier'
                    }
                },
                // =============================================================================
                // Экран "Календарь мероприятий"
                // =============================================================================
                {
                    path: '/events',
                    name: 'events',
                    component: () => import('./views/pages/Events.vue'),
                    meta: {
                        rule: 'cashier'
                    }
                },
                // =============================================================================
                // Экран "Менеджер тегов"
                // =============================================================================
                {
                    path: '/tags-manager',
                    name: 'tags-manager',
                    component: () => import('./views/pages/TagsManager.vue'),
                    meta: {
                        rule: 'cashier'
                    }
                },
                // =============================================================================
                // Экран "Отчет по тарифам за выбранный период"
                // =============================================================================
                {
                    path: '/tarifs-report',
                    name: 'tarifs-report',
                    component: () => import('./views/pages/TarifsReport.vue'),
                    meta: {
                        rule: 'cashier'
                    }
                },
                // =============================================================================
                // Редактирование заказа
                // =============================================================================
                {
                    path: '/edit-order',
                    name: 'edit-order',
                    component: () => import('./views/apps/EditOrder.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Главная', url: '/' },
                            { title: 'Заказ', active: true },
                        ],
                        pageTitle: 'Заказ',
                        rule: 'employee'
                    }
                },
                // =============================================================================
                // Добавление мероприятия
                // =============================================================================
                {
                    path: '/registration-event',
                    name: 'registration-event',
                    component: () => import('./views/apps/RegistrationEvent.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Главная', url: '/' },
                            { title: 'Регистрация на мероприятие', active: true },
                        ],
                        pageTitle: 'Регистрация на мероприятие',
                        rule: 'cashier'
                    }
                },
                // =============================================================================
                // Регистрация посетителя
                // =============================================================================
                {
                    path: '/registration',
                    name: 'registration',
                    component: () => import('./views/pages/Registration.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Главная', url: '/' },
                            { title: 'Регистрация', active: true },
                        ],
                        pageTitle: 'Регистрация',
                        rule: 'cashier'
                    }
                },
                // =============================================================================
                // Поиск посетителя
                // =============================================================================
                {
                    path: '/customer-search',
                    name: 'customer-search',
                    component: () => import('./views/pages/CustomerSearch.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Главная', url: '/' },
                            { title: 'Поиск посетителей', active: true },
                        ],
                        pageTitle: 'Поиск посетителей',
                        rule: 'employee'
                    }
                },
                // =============================================================================
                // История посетителя
                // =============================================================================
                {
                    path: '/customer-history/:id',
                    name: 'customer-history',
                    component: () => import('./views/apps/CustomerHistoryFull.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Главная', url: '/' },
                            { title: 'Поиск посетителей', url: '/customer-search' },
                            { title: 'История посетителя', active: true },
                        ],
                        pageTitle: 'История посетителя',
                        rule: 'employee'
                    },
                    props: true
                },
            ],
        },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
        // =============================================================================
        // PAGES
        // =============================================================================
                {
                    path: '/login/',
                    name: 'page-login',
                    component: () => import('@/views/pages/Login.vue'),
                    meta: {
                        rule: 'guest'
                    }
                },
                {
                    path: '/error-404',
                    name: 'page-error-404',
                    component: () => import('@/views/pages/Error404.vue'),
                    meta: {
                        rule: 'guest'
                    }
                },
                {
                    path: '/not-authorized',
                    name: 'page-not-authorized',
                    component: () => import('@/views/pages/NotAuthorized.vue'),
                    meta: {
                        rule: 'guest'
                    }
                },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/error-404'
        }
    ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

router.beforeEach(async (to, from, next) => {
    // Проверяем авторизован ли пользователь и устанавливаем роль для прав доступа
    let user = await auth.getCurrentUser();
    router.app.$acl.change(user.user_role);
    router.app.$store.commit('UPDATE_USER_ROLE', user.user_role);

    // TODO optional
    // router.app.$store.dispatch('setCurrentUser'); 
    // Сохранять в данный момент пользователя в state, т.е. на каждый переход по роутеру
    // И текущий id, инфу, имя и аватарку брать из state без асинхронных вызовов каждый раз
    // Авторизацию и права все равно проверять каждый раз обращаясь асинхронно по api к серверу

    // TODO *?1* проверка авторизации
    // TODO использовать везде dispatch, взаимодействие с юми в actions
    // UPDATE - async update from api and save to state and/or storage, GET - get from local state or localStorage
    // router.app.$store.dispatch();

    if (user.is_auth && user.user_role != 'guest') {
        return next();
    }

    // Страницы доступные без авторизации
    if (
        to.path === "/login" ||
        to.path === "/error-404"
    ) {
        return next();
    }

    return next('/login');
});

export default router
