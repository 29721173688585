/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
==========================================================================================*/


const mutations = {

    // ////////////////////////////////////////////
    // CLIENT, VISIT AND ORDERS
    // ////////////////////////////////////////////
    SET_CUSTOMER_EMAIL(state, val) {
        state.activeClient.info['e-mail'] = val;
    },
    SET_ACTIVE_CLIENT_INFO(state, val) {
        state.activeClient.info = val;
    },
    SET_ACTIVE_CLIENT(state, val) {
        state.activeClient = val;
    },
    SET_ACTIVE_VISIT(state, val) {
        state.activeClient.activeVisit = val;
        if (!val) return;

        // Необходимо преобразовать объекты в массивы для списка заказов и списков позиций и оплат в каждом заказе
        let visit = state.activeClient.activeVisit;
        visit.orders = Object.values(visit.orders);
        if (visit.time_order) {
            visit.time_order.items = Object.values(visit.time_order.items);
            visit.time_order.items.sort((a,b) => a.start_time - b.start_time);
            visit.time_order.payments = Object.values(visit.time_order.payments);
        }
        visit.orders.forEach(order => {
            order.items = Object.values(order.items);
            order.payments = Object.values(order.payments);
        });
    },
    END_ACTIVE_VISIT(state) {
        state.activeClient.activeVisit.end_time = Date.now();
    },
    ADD_ORDER(state, order) {
        order.items = Object.values(order.items);
        order.payments = Object.values(order.payments);
        state.activeClient.activeVisit.orders.push(order);
    },
    REMOVE_ORDER(state, order) {
        const index = state.activeClient.activeVisit.orders.findIndex(i => i.id == order.id);
        state.activeClient.activeVisit.orders.splice(index, 1);
    },
    SET_TIME_ORDER(state, order) {
        order.items = Object.values(order.items);
        order.items.sort((a,b) => a.start_time - b.start_time);
        order.payments = Object.values(order.payments);
        state.activeClient.activeVisit.time_order = order;
    },
    SET_EDITED_ORDER(state, order) {
        if (Object.keys(order).length != 0) {
            order.items = Object.values(order.items);
            order.payments = Object.values(order.payments);
        }
        state.editedOrder = order;
    },
    SET_CLIENT_COMMENTS(state, payload) {
        state.activeClient.comments = payload.items;
    },
    SET_TIME_TARIFFS_LIST(state, payload) {
        state.timeTariffsList = payload;
    },
    // ////////////////////////////////////////////
    // TIME
    // ////////////////////////////////////////////
    UPDATE_TIME(state) {
        state.now = Date.now();
    },
    SET_TIME(state, time) {
        state.now = time;
    },
    UPDATE_SESSION_START(state) {
        state.sessionStart = Date.now();
    },
    // ////////////////////////////////////////////
    // SESSION TIMEOUT
    // ////////////////////////////////////////////
    SET_IS_IDLE(state, val) {
        state.isIdle = val;
    },
    SET_IS_TIMEDOUT(state, val) {
        state.isTimedOut = val;
    },
    // ////////////////////////////////////////////
    // API REQUESTS UI BLOCKING
    // ////////////////////////////////////////////
    INCREMENT_REQUESTS_COUNT(state) {
        state.pendingRequestsCount += 1;
    },
    DECREMENT_REQUESTS_COUNT(state) {
        state.pendingRequestsCount -= 1;
    },
    // ////////////////////////////////////////////
    // MANAGER NOTIFICATIONS
    // ////////////////////////////////////////////
    SET_NOTIFICATIONS(state, payload) {
        state.notifications = payload;
    },
    // ////////////////////////////////////////////
    // IDEAL REPORT FILTER
    // ////////////////////////////////////////////
    SET_IDEAL_REPORT_FILTER(state, payload) {
        state.idealReportFilter = payload;
    },
    RESET_IDEAL_REPORT_FILTER(state) {
        state.idealReportFilter = {};
    },
    // ////////////////////////////////////////////
    // SIDEBAR & UI UX
    // ////////////////////////////////////////////
    UPDATE_SIDEBAR_WIDTH(state, width) {
        state.sidebarWidth = width;
    },
    UPDATE_SIDEBAR_ITEMS_MIN(state, val) {
        state.sidebarItemsMin = val;
    },
    TOGGLE_REDUCE_BUTTON(state, val) {
        state.reduceButton = val;
    },
    TOGGLE_CONTENT_OVERLAY(state, val) {
        state.bodyOverlay = val;
    },
    TOGGLE_IS_SIDEBAR_ACTIVE(state, value) {
        state.isSidebarActive = value;
    },
    UPDATE_THEME(state, val) {
        state.theme = val;
    },
    UPDATE_WINDOW_BREAKPOINT(state, val) {
        state.breakpoint = val;
    },
    UPDATE_PRIMARY_COLOR(state, val) {
        state.themePrimaryColor = val;
    },
    UPDATE_USER_ROLE(state, val) {
        state.userRole = val
        localStorage.setItem('userRole', val)
    },
    UPDATE_WINDOW_WIDTH(state, width) {
      state.windowWidth = width;
    },
}

export default mutations
