/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

var qs = require('qs');
import auth from "@/auth/authService.js";
import IdleJs from "@/idleJs"

var idleTracker = null;
var timeoutTracker = null;

const actions = {

    // ////////////////////////////////////////////
    // CLIENT, VISIT AND ORDERS
    // ////////////////////////////////////////////
    
    /**
     * Начинает новую сессию
     * 
     * Завершает текущую сессию, получает данные выбранного посетителя и устанавливает время старта сессит
     * @param {*} { commit, dispatch }
     * @param {*} clientBarcode штрихкод
     * @param {*} [callback=function(){}]
     */
    async startClientSession({ commit, dispatch }, clientBarcode, callback = function(){}) {
      if (await dispatch('endClientSession')) {
        await dispatch('getActiveClient', {barcode: clientBarcode, callback: callback});
        commit('UPDATE_SESSION_START');
        dispatch('startIdleTracker');
        callback();
      }
    },

    /**
     * Завершает текущую сессию
     *
     * Проверяет установлен ли тариф и если да, то обнуляет активного посетителя и редактируемый заказ
     * @param {*} { commit, state, getters }
     * @param {*} forced Флаг принудительного завершения сессии (без проверки выбранного тарифа)
     * @returns
     */
    endClientSession({ state, commit, dispatch, getters }, forced = false){
      if (!forced && state.activeClient.activeVisit && !getters['activeTimeTariff']) {
        this._vm.$vs.notify({
          color:'danger',
          title:'Не выбран тариф',
          text:'Для активного визита необходимо выбрать тариф'
        })
        return false;
      } else {
        try {
          dispatch('unlockCustomer');
        } catch {
          this._vm.$vs.notify({
            text: 'Ошибка при снятии блокировки с посетителя',
            color:'warning',
            iconPack: 'feather',
            icon:'icon-alert-circle'
          });
          return false;
        }
        dispatch('stopIdleTracker');
        commit('SET_ACTIVE_CLIENT', false); // clear active client data
        commit('SET_EDITED_ORDER', {});     // clear edited order
        return true;
      }
    },

    // Начало и завершение отслеживание активности пользователя
    async startIdleTracker({state, commit, dispatch}) {
      timeoutTracker = new IdleJs({
        idle: state.timeOutCountdown,
        onIdle: function() {
          commit("SET_IS_TIMEDOUT", true);
          dispatch("endClientSession", true);
        }
      })

      let idleThreshold = state.timeOutThreshold - state.timeOutCountdown;
      idleThreshold = (idleThreshold <= 0) ? 5000 : idleThreshold;
      idleTracker = new IdleJs({
        idle: idleThreshold,
        onIdle: function () { 
          commit('SET_IS_IDLE', true);
          timeoutTracker.start();
        },
        onActive: function () { 
          commit('SET_IS_IDLE', false);
          commit('SET_IS_TIMEDOUT', false);
          timeoutTracker.stop();
        },
      })

      idleTracker.start();
    },
    stopIdleTracker({ state, commit }) {
      commit('SET_IS_IDLE', false);
      commit('SET_IS_TIMEDOUT', false);
      if (idleTracker) idleTracker.stop();
      if (timeoutTracker) timeoutTracker.stop();
    },

    /**
     * Устанавливает активного клиента
     * 
     * Получает данные клиента по штрихкоду и устанавливает активный визит
     * @param {*} { commit, state, dispatch }
     * @param {*} clientBarcode штрихкод
     * @param {*} [callback=function(){}]
     */
    async getActiveClient({ commit, state, dispatch }, {barcode, callback, mode}) {
      callback = callback ? callback : function(){}; 
      mode = mode ? mode : 'all'; 
      try {
        let response = await this._vm.$http({
          method: 'post',
          url: '/udata/users/get_client/' + barcode + '/.json',
          data: qs.stringify({ open: mode === 'all' ? true : false }),
        });
        if (!response.data.error) {
          switch (mode) {
            case 'info':
              commit('SET_ACTIVE_CLIENT_INFO', response.data.info);
              break;
            case 'all':
              commit('SET_ACTIVE_CLIENT', response.data);
              await dispatch('getActiveVisit', response.data.id);
              break;
            default:
              break;
          }
          callback();
          this._vm.$vs.notify({
            color:'success',
            title:'Выбран посетитель',
            text:'ID: ' + state.activeClient.id
          })
        } else {
          this._vm.$vs.notify({
            title:'Ошибка getActiveClient',
            text: response.data.error,
            color:'warning',
            iconPack: 'feather',
            icon:'icon-alert-circle'
          })          
        }
      } catch (error) {
        this._vm.$vs.notify({
          title:'Ошибка getActiveClient',
          text: error,
          color:'danger',
          iconPack: 'feather',
          icon:'icon-alert-circle'
        })
      }
    },

    /**
     * Обновляет данные текущего посетителя
     */
    updateActiveCustomer({ state, dispatch }, callback = function(){}) {
      let payload = {
        id:  state.activeClient.id,
        data: {
          new: state.activeClient.info
        }
      }

      this._vm.$http({
        method: 'post',
        url: '/udata/users/update_object/' + state.activeClient.id + '/.json',
        data: qs.stringify(payload),
      }).then((response) => {
        if (response.data.udata && response.data.udata.error) {
          this._vm.$vs.notify({
            color:'warning',
            title:'Не удалось обновить данные пользователя',
            text:response.data.udata.error
          })
          dispatch('getActiveClient', {barcode: state.activeClient.barcode, mode: 'info'});
        } else {
          dispatch('getActiveClient', {barcode: state.activeClient.barcode, callback: callback, mode: 'info'});
        }
      }).catch((err) => {
        this._vm.$vs.notify({
            color:'danger',
            title:'Ошибка updateActiveCustomer',
            text:err
        })                            
      })
    },
  
    /**
     * Устанавливает активный визит для выбранного посетителя
     *
     * @param {*} { commit, state }
     * @param {*} clientId
     */
    async getActiveVisit({ commit, state, dispatch }, clientId) {
      let response;
      try {
        response = await this._vm.$http.get('/udata/users/get_active_visit/' + clientId + '/.json')
      } catch (error) {
        this._vm.$vs.notify({
          title:'Ошибка getActiveVisit',
          text: error,
          color:'danger',
          iconPack: 'feather',
          icon:'icon-alert-circle'
        })
      }
      if (!response.data.error) {
        commit('SET_ACTIVE_VISIT', response.data);
        dispatch('updateTimeOrder');
        this._vm.$vs.notify({
          color:'success',
          title:'Установлен активный визит',
          text:'ID: ' + state.activeClient.activeVisit.id
        })
      } else {
        this._vm.$vs.notify({
          title:'Ошибка getActiveVisit',
          text: response.data.error,
          color:'warning',
          iconPack: 'feather',
          icon:'icon-alert-circle'
        })          
      }
    },


    async addVisit({ commit, state, dispatch }) {
      let payload = {
        name: "Визит",
        data: {
          new: {
            manager_id: JSON.parse(localStorage.getItem('userInfo')).user_id,
            customer_id: state.activeClient.id,
            start_time: Date().toString(),
          }
        }
      }
      try {
        await this._vm.$http({
          method: 'post',
          url: '/udata/users/add_visit.json',
          data: qs.stringify(payload),
          withCredentials: true
        })        
      } catch (err) {
        this._vm.$vs.notify({
          color:'danger',
          title:'Ошибка addVisit',
          text:err
        })        
      }
      this._vm.$vs.notify({
        color:'success',
        title:'Визит успешно создан',
      });

      await dispatch('getActiveVisit', state.activeClient.id);
      dispatch('addOrder', {isTimeOrder: true}); // Автоматически добавляем заказ-время после создания визита
    },


    async endVisit({ commit, state, getters, dispatch }, skip_check=false) {
      if (getters['orders'](2).length > 0 && !skip_check) {
        this._vm.$vs.notify({
          color:'danger',
          title:'Неоплаченные заказы',
          text:'Необходимо сначала зафиксировать оплату у всех заказов'
        })    
        return false;
      }

      await dispatch('removeTempCard');

      commit('END_ACTIVE_VISIT'); // устанавливаем время завершения визита
      let payload = {
        name: "Визит",
        id: state.activeClient.activeVisit.id,
        data: {
          new: { end_time: Date(state.activeClient.activeVisit.end_time).toString() }
        }
      }
      this._vm.$http({
        method: 'post',
        url: '/udata/users/end_visit/' + payload.id,
        data: qs.stringify(payload),
        withCredentials: true
      }).then(() => {
        commit('SET_ACTIVE_VISIT', false);
        commit('SET_EDITED_ORDER', {});
        this._vm.$vs.notify({
          color:'success',
          title:'Визит успешно завершен'
        });
      }).catch((err) => {
        this._vm.$vs.notify({
            color:'danger',
            title:'Ошибка endVisit',
            text:err
        })                            
      })
    },


    async removeTempCard({ state, dispatch }) {
      let temp_card = state.activeClient.activeVisit.temp_card;
      if (temp_card) {
        let payload = {
          id: temp_card,
          data: {
            new: { temp_card_customer: '' }
          }
        }
        let response;
        // let response = await dispatch('updateObject', [temp_card, payload]);
        try {
          response = await this._vm.$http({
            method: 'post',
            url: '/udata/users/update_object/' + temp_card,
            data: qs.stringify(payload),
            withCredentials: true
          })        
        } catch (err) {
          this._vm.$vs.notify({
            color:'danger',
            title:'Ошибка removeTempCard',
            text:err
          })        
        }

        if (!response.data.error) {
          this._vm.$vs.notify({
            color:'success',
            title:'Посетитель отвязан от временной карты',
          });
        } else {
          this._vm.$vs.notify({
            title:'Ошибка removeTempCard',
            text: response.data.error,
            color:'warning',
            iconPack: 'feather',
            icon:'icon-alert-circle'
          })          
        }
        
        await dispatch('getActiveVisit', state.activeClient.id);
      }
    },


    async addTempCard({ state, dispatch }, tempCardBarCode) {
      let response;
      try {
        response = await this._vm.$http({
          method: 'post',
          url: '/udata/users/add_temp_card/' + tempCardBarCode + 
               '/' + state.activeClient.id+ '.json',
          withCredentials: true
        })        
      } catch (err) {
        this._vm.$vs.notify({
          color:'danger',
          title:'Ошибка addTempCard',
          text:err
        })        
      }
      if (!response.data.error) {
        this._vm.$vs.notify({
          color:'success',
          title:'Временная карта успешно добавлена',
          text: response.data.success,
        });
      } else {
        this._vm.$vs.notify({
          title:'Ошибка addTempCard',
          text: response.data.error,
          color:'warning',
          iconPack: 'feather',
          icon:'icon-alert-circle'
        })          
      }
      
      await dispatch('getActiveVisit', state.activeClient.id);
    },


    async addOrder({ commit, state, getters, dispatch }, args) {
      let now = Date.now();

      args.isTimeOrder = args.isTimeOrder ? args.isTimeOrder : false; 
      args.undo_callback = args.undo_callback ? args.undo_callback : function(){}; 
      args.callback = args.callback ? args.callback : function(){}; 

      if (args.payload) {
        args.payload = {
          name: "Заказ",
          active_client_type_id: state.activeClient.info.client_type.id,
          data: {
            new: args.payload
          }
        }
      } else {
        args.payload = {
          name: "Заказ",
          active_client_type_id: state.activeClient.info.client_type.id,
          data: {
            new: {
              is_time_order: args.isTimeOrder,
              visit_id: state.activeClient.activeVisit ? state.activeClient.activeVisit.id : null,
              status: 0,
              manager_id: JSON.parse(localStorage.getItem('userInfo')).user_id,
              customer_id: state.activeClient.id, 
              order_date: new Date(now).toString(),
              time: now,
              tariff_object_id: getters.activeTimeTariff.object_id,
              client_type_id: state.activeClient.info.client_type.id,
              items: [],
              payments: []
            }
          }
        }
      }

      let response;
      try {
        let visit_id = state.activeClient.activeVisit ? state.activeClient.activeVisit.id : '';
        response = await this._vm.$http({
          method: 'post',
          url: '/udata/users/add_order/' + visit_id + '/.json',
          data: qs.stringify(args.payload),
          withCredentials: true
        })
      } catch (error) {
        args.undo_callback();
        this._vm.$vs.notify({
            color:'danger',
            title:'Ошибка addOrder',
            text:error
        });
      }

      if (!response.data.udata) {
        let order = response.data;
        if (args.isTimeOrder) {
          commit('SET_TIME_ORDER', order);
        } else {
          if (state.activeClient.activeVisit) {
            commit('ADD_ORDER', order);
          }
          commit('SET_EDITED_ORDER', order);
          args.callback(order);
        }
        this._vm.$vs.notify({
          color:'success',
          title:'Заказ успешно создан',
          text:'ID заказа: ' + response.data.id
        })
        return order;
      } else {
        args.undo_callback();
        this._vm.$vs.notify({
          color:'warning',
          title:'Ошибка при создании заказа',
          text:response.data.udata.error
        })
      }
    },


    removeOrder({ commit, dispatch }, order) {
      this._vm.$http({
        method: 'get',
        url: '/udata/users/delete_order/' + order.id + '/.json',
        withCredentials: true
      }).then((response) => {
        if (response.data) {
          commit('REMOVE_ORDER', order);
          this._vm.$vs.notify({
            color:'success',
            title:'Заказ успешно удален'
          })
        } else {
          this._vm.$vs.notify({
            color:'warning',
            title:'Не удалось удалить заказ'
          })
        }
      }).catch((err) => {
        this._vm.$vs.notify({
            color:'danger',
            title:'Ошибка removeOrder',
            text:err
        })                            
      })
    },


    getOrder({ commit, state }, [order_id, undo_callback = function(){}, callback = function(){}]) {
      this._vm.$http({
        method: 'get',
        url: '/udata/users/get_order/' + order_id + '/.json',
        withCredentials: true
      }).then((response) => {
        if (!response.data.error) {
          let order = response.data;
          if (state.activeClient.activeVisit) {
            let local_order = state.activeClient.activeVisit.orders.find(obj => { return obj.id === order.id; })
            if (local_order !== undefined) {
              commit('eCommerce/UPDATE_ORDER', [order, local_order]);
              order = local_order;
            } 
          }
          commit('SET_EDITED_ORDER', order);
          callback(order);
        } else {
          undo_callback();
          this._vm.$vs.notify({
            color:'warning',
            title:'Ошибка при получении заказа',
            text:response.data.error
          })
        }
      }).catch((err) => {
        undo_callback();
        this._vm.$vs.notify({
          color:'danger',
          title:'Ошибка getOrder',
          text:err
        })                            
      })
    },


    setEditedOrder({ commit }, order) {
      commit('SET_EDITED_ORDER', order);
    },


    getClientComments({ commit, state }) {
      this._vm.$http({
        method: 'get',
        url: '/udata/users/get_client_comments/' + state.activeClient.id + '/.json',
        withCredentials: true
      })   
      .then((response) => {
        if (!response.data.error) {
          commit('SET_CLIENT_COMMENTS', response.data);  
          this._vm.$vs.notify({
            color:'success',
            title:'Получены комментарии',
          })
        } else {
          this._vm.$vs.notify({
            title:'Ошибка getClientComments',
            text: response.data.error,
            color:'warning',
            iconPack: 'feather',
            icon:'icon-alert-circle'
          })          
        }
      })
      .catch((error) => {
        this._vm.$vs.notify({
          title:'Ошибка getClientComments',
          text: error,
          color:'danger',
          iconPack: 'feather',
          icon:'icon-alert-circle'
        })
      });
    },

    // ////////////////////////////////////////////
    // DEFAULT STORE STATE
    // ////////////////////////////////////////////
    /**
     * Получение списка всех временных тарифов
     */
    getTimeTariffsList({ commit }) {
      fetch("/udata/catalog/get_time_tariffs/.json")
      .then(response => response.json())
      .then(response => {
        if (response.item) {
          commit('SET_TIME_TARIFFS_LIST', Object.values(response.item).sort((a,b) => a.price - b.price));
        }
      })
      .catch(e => {
        this.$vs.notify({
          color:'danger',
          title:'Не удалось получить список тарифов',
          text: e
        })
      });
    },

    // ////////////////////////////////////////////
    // TIME
    // ////////////////////////////////////////////
    startTimer({ dispatch }) {
      dispatch('periodicUpdate');
      setInterval(() => { dispatch('periodicUpdate') },
                         1000 * 60);
    },
    periodicUpdate({ dispatch, commit }) {
      commit('UPDATE_TIME');
      dispatch('updateTimeOrder');
      dispatch('lockCustomer');
    },

    async lockCustomer({ state }) {
      if (!state.activeClient) return;
      await this._vm.$http({
        method: 'get',
        url: `/udata/users/lock_customer/${state.activeClient.id}`,
      })
    },
    async unlockCustomer({ state }) {
      if (!state.activeClient) return;
      await this._vm.$http({
        method: 'get',
        url: `/udata/users/unlock_customer/${state.activeClient.id}`,
      })
    },
    
    // ////////////////////////////////////////////
    // TIME ORDER
    // ////////////////////////////////////////////
    async updateTimeOrder({ state, getters, dispatch, commit }, undo_callback = function(){}) {
      let timeOrder = getters.timeOrder;
      if (!timeOrder) return;

      // Начавшиеся тарифы - у которых время начала в прошлом
      let started_tariffs = timeOrder.items.filter((e) => (e.start_time < state.now));

      for (var i = started_tariffs.length - 1; i >= 0; i--) {
        let item = timeOrder.items[i];

        // Если нет end_time - ставим равным start_time следующего тарифа
        if (!item.end_time) {
          let end_time = timeOrder.items[i+1] ? timeOrder.items[i+1].start_time : undefined;
          commit('eCommerce/UPDATE_TIME_TARIFF_ENDTIME', [item, end_time]);
        }

        // Пересчитываем для всех тарифов item_amount (минуты/штуки) = Min(end_time, now) - start_time
        let item_amount;
        if (item.end_time) {
          item_amount = this._vm.$helpers.getTimeIntervalInMins(Math.min(state.now, item.end_time) - item.start_time);
        } else {
          item_amount = this._vm.$helpers.getTimeIntervalInMins(state.now - item.start_time); // TODO remove (unnecessary)
        }
        commit('eCommerce/UPDATE_TIME_TARIFF_AMOUNT', [item, item_amount]);
      }
      // Обновляем заказ - актуальные значения минут по тарифам - получаем из ЮМИ корректные посчитанные цены заказа
      await dispatch('eCommerce/updateOrder', [getters.timeOrder, undo_callback]);
    },

    // ////////////////////////////////////////////
    // MANAGER NOTIFICATIONS
    // ////////////////////////////////////////////
    setNotifications({ commit }, payload) {
      commit('SET_NOTIFICATIONS', payload);
    },

    // ////////////////////////////////////////////
    // COMMON OBJECT
    // ////////////////////////////////////////////
    async updateObject({state}, [object_id, payload]) {
      try {
        let response = await this._vm.$http({
          method: 'post',
          url: '/udata/users/update_object/' + object_id,
          data: qs.stringify(payload),
          withCredentials: true
        });
        return response;
      } catch (err) {
        this._vm.$vs.notify({
          color:'danger',
          title:'Ошибка updateObject',
          text:err
        })        
      }
    },


    // ////////////////////////////////////////////
    // EDIT PERMISSIONS
    // ////////////////////////////////////////////
    // TODO *?1* проверка авторизации
    async isEditOrderAllowed({state}, order) {
      if (this._vm.$acl.check('admin') || this._vm.$acl.check('cashier')) {
        return true;
      }
      if (order.is_time_order || order.status == 2) {
        return false;
      }
      return true;
    },

    async isEditPaymentsAllowed({state}, order) {
      if (this._vm.$acl.check('admin')) {
        return true;
      }
      if (order.status == 2) {
        return false;
      }
      return true;
    },

    async isRemovePaymentsAllowed({state}, order) {
      if (!this._vm.$acl.check('admin')) {
        return false;
      } else {
        return true;
      }
    },

    async isMyOrder({state}, order) {
      let user = await auth.getCurrentUser();
      let user_id = user.user_info.user_id;

      if (this._vm.$acl.check('admin')) {
        return true;
      }
      if (user_id == order.manager_id) {
        return true;
      }
      return false;
    },

    // ////////////////////////////////////////////
    // SIDEBAR & UI UX
    // ////////////////////////////////////////////

    updateSidebarWidth({ commit }, width) {
      commit('UPDATE_SIDEBAR_WIDTH', width);
    },
    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY');
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val);
    },
    updateUserRole({ commit }, val) {
      commit('UPDATE_USER_ROLE', val);
    },
    updateWindowWidth({ commit }, width) {
      commit('UPDATE_WINDOW_WIDTH', width);
    },
}

export default actions
