/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import themeConfig from "@/../themeConfig.js";
import colors from "@/../themeConfig.js";

const state = {
  // *** Theme state ***
  isSidebarActive: true,
  breakpoint: null,
  sidebarWidth: "default",
  reduceButton: themeConfig.sidebarCollapsed,
  bodyOverlay: false,
  sidebarItemsMin: false,
  theme: themeConfig.theme || "light",
  themePrimaryColor: colors.primary,

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar component
  windowWidth: null,

  // *** App state ***
  timeOutCountdown: 5000,
  timeOutThreshold: 10000,
  isIdle: false,
  isTimedOut: false,

  userRole: null,

  activeClient: false,

  now: Date.now(),
  sessionStart: Date.now(),

  pendingRequestsCount: 0, // Количество незавершенных запросов к API

  editedOrder: {},
  notifications: [],
  idealReportFilter: {},

  timeTariffsList: []
};

export default state;
