import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from '@/router'

Vue.use(AclInstaller)

let initialRole = 'guest';
// let allowedUserRoles = ['barista', 'cashier'];
// if (allowedUserRoles.includes(localStorage.getItem('userRole'))) {
//   initialRole = localStorage.getItem('userRole');
// }

export default new AclCreate({
  initial: initialRole,
  notfound: '/pages/not-authorized',
  router,
  acceptLocalRules: true,
  globalRules: {
    guest: new AclRule('guest').or('barista').or('cashier').or('admin').generate(),
    employee: new AclRule('barista').or('cashier').or('admin').generate(),
    barista: new AclRule('barista').or('admin').generate(),
    cashier: new AclRule('cashier').or('admin').generate(),
    admin: new AclRule('admin').generate()
  },

  // TODO переделать проверку авторизации и/или роли пользователя с использование middleware
  // middleware: async acl => {
  //   let currentUser = await auth.getCurrentUser();
  //   let userRole = currentUser.user_role;
  //   acl.change(userRole);
  // }
})
