/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import auth from "@/auth/authService.js";

export default {
    isUserLoggedIn: async () => {
        let user = await auth.getCurrentUser();
        let isAuthenticated = false;

        if (user.is_auth) {
            isAuthenticated = true;
        }
        return isAuthenticated;
    },
    managerId: async () => {
        let user = await auth.getCurrentUser();
        return user.user_info.user_id;
    },
}