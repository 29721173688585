/*=========================================================================================
  File Name: moduleEcommerceMutations.js
  Description: Ecommerce Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  UPDATE_ITEM_QUANTITY(state, payload) {
    payload.order.items[payload.index].item_amount = payload.item_amount
  },
  REMOVE_ITEM_FROM_ORDER(state, [item, order]) {
    const index = order.items.findIndex(i => i.objectID == item.objectID)
    if (index < 0) {
      order.items.push(item)
    } else {
      order.items.splice(index, 1)
    }
  },
  ADD_ITEM_TO_ORDER(state, [item, order]) {
    order.items.push(item)
  },

  UPDATE_ORDER_STATUS(state, [newStatus, order]) {
    order.status = newStatus;
  },

  UPDATE_ORDER(state, [payload, order]) {
    payload.items = Object.values(payload.items);
    payload.items.sort((a,b) => a.start_time - b.start_time);
    payload.payments = Object.values(payload.payments);
    payload.payments.forEach(element => {
      element.payment_amount = Number(element.payment_amount)
    });
    Object.assign(order, payload);
  },
  REMOVE_ORDER_PAYMENT(state, [index, order]) {
    order.payments.splice(index, 1);
  },
  ADD_ORDER_PAYMENT(state, [payment, order]) {
    order.payments.push(payment);
  },
  
  ADD_TIME_TARIFF(state, item) {
    this.state.activeClient.activeVisit.time_order.items.push(item);
  },
  REMOVE_TIME_TARIFF(state, [order, index]) {
    order.items.splice(index, 1);
  },
  END_TIME_TARIFF(state, [activeTariff, endTime, item_amount]) {
    if (item_amount === 0) {
      let items = this.state.activeClient.activeVisit.time_order.items;
      items.splice(items.indexOf(activeTariff), 1);
    } else {
      activeTariff.end_time = endTime;
      activeTariff.item_amount = item_amount;
    }
  },
  UPDATE_TIME_TARIFF_ENDTIME(state, [tariff, end_time]) {
    tariff.end_time = end_time;
  },
  UPDATE_TIME_TARIFF_AMOUNT(state, [tariff, item_amount]) {
    tariff.item_amount = item_amount;
  }  
}
