/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

const getters = {
  isDevDomain: () => {
    return /^dev\..*/.test(window.location.hostname);
  },

  loading: (state) => {
    return state.pendingRequestsCount > 0;
  },

  // Возвращает заказы по фильтру (<3 - все, <2 - неоплаченные)
  orders: (state) => (status) => {
    if (state.activeClient.activeVisit) {
      return state.activeClient.activeVisit.orders.filter(o => o.status < status);
    }
    return [];
  },

  timeOrder: (state) => {
    if (state.activeClient && state.activeClient.activeVisit) {
      return state.activeClient.activeVisit.time_order;
    }
    return null;
  },

  activeEventTariffItem: (state, getters) => {
    if (getters.timeOrder) {
      let event_tariffs = getters.timeOrder.items.filter(t => (t.event_id !== null));
      for(let i = 0; i < event_tariffs.length; i++) {
        if (event_tariffs[i] && event_tariffs[i].end_time > state.now) {
          return event_tariffs[i];
        }
      }
    }
    return false;
  },

  // DEPRECATED
  getTimeOrderDiscount: (state, getters) => {
    // Если в заказе есть только тарифы с нулем минут - скидку не применять
    if ((!getters.timeOrder.items || getters.timeOrder.items.length === 0) || (Math.max.apply(null, getters.timeOrder.items.map((t) => (t.item_amount))) === 0)) {
      return 0;
    }
    
    // Отдельно тарифы (с мероприятиями)
    let events_price = 0;
    let eventItems = getters.timeOrder.items.filter(i => i.event_id !== null && i.is_prepaid === null);
    eventItems.forEach(function(eventItem) {
      let start_time = new Date(eventItem.start_time);
      let start_dow = start_time.getDay();
      let tariff = state.timeTariffsList.find((i) => (i.objectID === eventItem.objectID));
      let min_receipt = tariff.min_receipt[start_dow];
      let max_receipt = tariff.max_receipt[start_dow] ? tariff.max_receipt[start_dow] : +Infinity;
      events_price =+ Math.min(Math.max(eventItem.total_price, min_receipt), max_receipt);
    });

    // Отдельно тарифы (не связанные с мероприятиями)
    let tariffs_price = 0;
    let tariffItems = getters.timeOrder.items.filter(i => i.event_id === null && i.is_prepaid === null);

    if (tariffItems.length > 0) {
      tariffItems.forEach(function(tariffItem) {
        tariffs_price = tariffs_price + tariffItem.total_price;
      });

      // Находим самый дорогой тариф в заказе
      let max_item = {};
      tariffItems.map((tariff) => {
        max_item = (max_item.price > tariff.price) ? max_item : tariff;
      });

      // Находим этот тариф в списке тарифов
      // TODO проверить необходим ли еше список тарифов timeTariffsList
      let max_tariff = state.timeTariffsList.find((i) => (i.objectID === max_item.objectID));

      // Получаем величину скидки, которую необходимо добавить к заказу, чтобы соотв. условиям стоп-чека
      let start_time = new Date(max_item.start_time);
      let start_dow = start_time.getDay(); // порядковый номер дня недели, когда стартовал тариф

      let min_receipt = max_tariff.min_receipt[start_dow];
      let max_receipt = max_tariff.max_receipt[start_dow] ? max_tariff.max_receipt[start_dow] : +Infinity;
      tariffs_price = Math.min(Math.max(tariffs_price, min_receipt), max_receipt);
    }

    return (getters.timeOrder.original_total_price - (tariffs_price + events_price));
  },

  itemsWithoutActiveTariff: (state, getters) => (order) => {
    return order.items.filter(i => i != getters['activeTimeTariff']).sort((a,b) => a.start_time - b.start_time);
  },

  activeTimeTariff: (state) => {
    if (!state.activeClient ||
        !state.activeClient.activeVisit) {
          return false
    }

    let timeOrder =  state.activeClient.activeVisit.time_order
    if (!timeOrder ||
        !timeOrder.items ||
        timeOrder.items.length == 0) {
          return false
    }
    let activeTimeTariff = false

    timeOrder.items.forEach(element => {
      if (element.start_time < state.now && (!element.end_time || (state.now < element.end_time))) activeTimeTariff = element
    })
    return activeTimeTariff
  },

  // DEPRECATED
  activeVisitTotalPrice: (state, getters) => (status) => {
    if (!state.activeClient.activeVisit) return 0;
    let total = 0;
    total += getters['activeTimeTariffPrice'];
    total += getters['eCommerce/orderTotalPrice'](state.activeClient.activeVisit.time_order)
    getters['orders'](status).forEach(o => {
      total += getters['eCommerce/orderTotalPrice'](o)
    })
    return total;
  },

  isOrdersWithoutFixedPayments: (state, getters) => {
    if (getters['orders'](2).length > 0) {
      return true;
    }
    return false;
  }
}

export default getters