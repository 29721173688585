<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
	<div id="app">
		<router-view />
    <idle-warning />
	</div>
</template>

<script>
import themeConfig from '@/../themeConfig.js'
import IdleWarning from '@/components/IdleWarning.vue'
// TODO import js с настроечными константами для api umi

export default {
  components: { IdleWarning },
  watch: {
    '$store.state.theme'(val) {
      this.toggleClassInBody(val);
    }
  },
  methods: {
    toggleClassInBody(className) {
      if (className == 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
        document.body.classList.add('theme-dark');
      } else if (className == 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
        document.body.classList.add('theme-semi-dark');
      } else {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
      }
    },
    handleWindowResize(event) {
      this.$store.dispatch('updateWindowWidth', event.currentTarget.innerWidth);
    },
  },
  mounted() {
    this.$store.dispatch("auth/updateCurrentUser");

    this.toggleClassInBody(themeConfig.theme)
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleWindowResize);
    });
    this.$store.dispatch('updateWindowWidth', window.innerWidth);
    this.$store.dispatch('startTimer');
    this.$store.dispatch('getTimeTariffsList');


    // AXIOS CONFIG AND INTERCEPTORS
    this.$http.defaults.timeout = 60000;
    this.$http.defaults.withCredentials = true;
    this.$http.defaults.blocking = true;

    this.$http.interceptors.request.use((config) => {
      if (config.blocking) {
        this.$store.commit('INCREMENT_REQUESTS_COUNT');
      }
      return config;
    });

    this.$http.interceptors.response.use(async (response) => {
      if (response.config.blocking) {
        this.$store.commit('DECREMENT_REQUESTS_COUNT');
      }
      return response;
    }, (error) => {
      if (error.config.blocking) {
        this.$store.commit('DECREMENT_REQUESTS_COUNT');
      }
      return Promise.reject(error);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
}

</script>
