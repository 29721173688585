export const globals = {
  DEV_DOMAIN: "https://dev.timer.k7.su",
  PROD_DOMAIN: "https://timer.k7.su",
  CATALOG_ROOT_CATEGORY_ID: 4,
  BAR_ROOT_CATEGORY_ID: '/shop/catalog-bar/',
  TARIFFS_ROOT_CATEGORY_ID: 236,
  EVENTS_ROOT_CATEGORY_ID: 484,
  RENT_EVENT_TYPE: 7569,
  CLUB_EVENT_TYPE: 7570,
  MIX_EVENT_TYPE: 7571,
  EMPTY_TARIFF_ID: 291
};
