/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import router from "@/router";
import auth from "@/auth/authService.js";

export default {
  loginAttempt({ dispatch }, payload) {
    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation
    };
    dispatch("login", newPayload);
  },
  async login({ commit, dispatch }, payload) {
    await auth.login(payload.userDetails);
    // if (login.data.user_id) // TODO проверка успешности логина

    auth.getCurrentUser().then(
      result => {
        if (payload.closeAnimation) payload.closeAnimation();
        if (!result.is_auth) {
          payload.notify({
            title: "Ошибка",
            text: "Авторизоваться не удалось. Проверьте логин и пароль.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        } else {
          if (result.user_role != "guest") {
            commit("UPDATE_AUTHENTICATED_USER", result);
            commit("UPDATE_USER_ROLE", result.user_role, { root: true });
            router.push(router.currentRoute.query.to || "/");
          } else {
            payload.notify({
              title: "Ошибка",
              text: "Попытка залогиниться под гостем или посетителем.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        }
      },
      err => {
        if (payload.closeAnimation) payload.closeAnimation();
        payload.notify({
          time: 2500,
          title: "Ошибка",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      }
    );
  },
  logout({ dispatch }) {
    auth.logout();
    localStorage.removeItem("userRole"); // TODO use commit update
    dispatch("endClientSession", null, { root: true });
    router.push("/login");
  },
  async updateCurrentUser({ commit }) {
    let user = await auth.getCurrentUser();
    commit("UPDATE_AUTHENTICATED_USER", user);
  },

  // TODO авторизацию (плюс гарды в роутинге) нужно перепроверить и исправить
  // updateUsername и updateAuthenticatedUser - не используются
  updateUsername({ commit }, payload) {
    payload.user
      .updateProfile({
        displayName: payload.username
      })
      .then(() => {
        // If username update is success
        // update in localstorage
        let newUserData = Object.assign({}, payload.user.providerData[0]);
        newUserData.displayName = payload.username;
        commit("UPDATE_AUTHENTICATED_USER", newUserData);

        // If reload is required to get fresh data after update
        // Reload current page
        if (payload.isReloadRequired) {
          router.push(router.currentRoute.query.to || "/");
        }
      })
      .catch(err => {
        payload.notify({
          time: 8800,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
  },
  updateAuthenticatedUser({ commit }, payload) {
    commit("UPDATE_AUTHENTICATED_USER", payload);
  }
};
