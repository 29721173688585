/*=========================================================================================
  File Name: moduleEcommerceGetters.js
  Description: Ecommerce Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
    orderTotalPrice: () => order => {
        return order.actual_total_price;
    },
    isInOrder: () => (order, needle) => {
        return order.items.findIndex((item) => 
            ((item.objectID == needle.objectID) && (item.trade_offer_id == needle.trade_offer_id))
        )
    },
}