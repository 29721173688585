/*=========================================================================================
  File Name: moduleEcommerceActions.js
  Description: Ecommerce Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import helpers from '@/helpers.js'
var qs = require('qs');
var _debounce = require('lodash/debounce');

export default {
  removeOrderPayment({ commit, dispatch }, payload) {
    let undo_payment = {
      paymentType: {id: payload.order.payments[payload.index].id },
      paymentAmount: payload.order.payments[payload.index].paymentAmount
    };
    let undo = () => commit('ADD_ORDER_PAYMENT', [undo_payment, payload.order]);
    commit('REMOVE_ORDER_PAYMENT', [payload.index, payload.order]);
    commit('UPDATE_ORDER_STATUS', [1, payload.order]);
    payload.skip_update || dispatch('updateOrder', [payload.order, undo, function(){}, false]);
  },
  addOrderPayment({ commit, dispatch }, payload) {
    let undo = () => commit('REMOVE_ORDER_PAYMENT', [payload.order.length - 1, payload.order]);
    commit('ADD_ORDER_PAYMENT', [payload.payment, payload.order]);
    commit('UPDATE_ORDER_STATUS', [1, payload.order]);
    payload.skip_update || dispatch('updateOrder', [payload.order, undo, function(){}, false]);
  },

  updateItemQuantity({ commit, dispatch }, payload){
    let undo = () => commit('UPDATE_ITEM_QUANTITY', {index: payload.index, order: payload.order, item_amount: payload.order.items[payload.index].item_amount});
    commit('UPDATE_ITEM_QUANTITY', payload);
    payload.skip_update || dispatch('debouncedUpdateOrder', [payload.order, undo, payload.callback]);
  },
  async removeItemFromOrder({ commit, dispatch }, payload) {
    let undo = () => commit('ADD_ITEM_TO_ORDER', [payload.item, payload.order]);
    commit('REMOVE_ITEM_FROM_ORDER', [payload.item, payload.order]);
    payload.skip_update || await dispatch('updateOrder', [payload.order, undo, payload.callback]);
  },
  async addItemToOrder({ commit, dispatch }, payload) {
    this._vm.$set(payload.item, 'item_amount', 1);
    let undo = () => commit('REMOVE_ITEM_FROM_ORDER', [payload.item, payload.order]);
    commit('ADD_ITEM_TO_ORDER', [payload.item, payload.order]);
    payload.skip_update || await dispatch('updateOrder', [payload.order, undo, payload.callback]);
  },

  async updateOrderStatus({ commit, dispatch }, payload) {
    let undo = () => commit('UPDATE_ORDER_STATUS', [payload.status - 1, payload.order]);
    await commit('UPDATE_ORDER_STATUS', [payload.status, payload.order]);
    payload.skip_update || await dispatch('updateOrder', [payload.order, undo, payload.callback, false]);
  },

  debouncedUpdateOrder: _debounce(function({dispatch}, [order, undo_callback = function(){}, callback = function(){}, refresh=true]) {
    dispatch('updateOrder', [order, undo_callback, callback, refresh]);
  }, 500),
  async updateOrder({ commit, dispatch }, [order, undo_callback = function(){}, callback = function(){}, refresh=true]) {
    let payload = {
      name: "Заказ",
      refresh: refresh,
      // передаем id товара текущего тарифа для получения цен со скидками
      active_tariff_object_id: order.tariff_object_id,
      active_client_type_id: order.client_type_id,
      data: {
        new: 
          Object.assign({}, order)
      }
    }

    payload.data.new.order_closed_date = payload.data.new.order_closed_date ? new Date(payload.data.new.order_closed_date).toString() : false;
    payload.data.new.items = false;
    payload.data.new.items = order.items.map(x => 
      Object.assign({}, x, { 
        start_time: x.start_time ? new Date(x.start_time).toString() : false,
        end_time: x.end_time ? new Date(x.end_time).toString() : false
      })
    );
    payload.data.new.payments = false;
    payload.data.new.payments = order.payments.map(x => 
      Object.assign({}, x, { 
        payment_time: new Date(x.payment_time).toString(),
      })
    );
    // TODO use qs.stringify option - serializeDate
    payload.data.new.order_items = order.items.filter(x => x.id).map(x => x.id);
    payload.data.new.order_payments = order.payments.filter(x => x.id).map(x => x.id);
    // qs.stringify не передает пустые массивы/объекты 
    if (payload.data.new.order_items.length == 0) { payload.data.new.order_items = null }
    if (payload.data.new.order_payments.length == 0) { payload.data.new.order_payments = null }
    
    let response;
    try {
      response = await this._vm.$http({
        method: 'post',
        url: '/udata/users/update_order/' + order.id + '/.json',
        data: qs.stringify(payload),
        withCredentials: true
      })
    } catch(err) {
      undo_callback();
      this._vm.$vs.notify({
        color:'danger',
        title:'Ошибка при обновлении заказа ' + order.id,
        text:err
      });
    }

    if (!response.data.error) {
      commit('UPDATE_ORDER', [response.data, order]);
      if (response.data.warning) {
        this._vm.$vs.notify({
          color:'warning',
          title:'Ошибка при обновлении заказа ' + order.id,
          text:response.data.warning
        })
      }
      callback();
    } else {
      undo_callback();
      this._vm.$vs.notify({
        color:'warning',
        title:'Ошибка при обновлении заказа ' + order.id,
        text:response.data.error
      })
    }
  },


  async addTimeTariff({ commit, dispatch }, tariff) {
    let undo = () => commit('END_TIME_TARIFF', [tariff, false, 0]);
    commit('ADD_TIME_TARIFF', tariff);
    await dispatch('updateTimeOrder', undo, {root: true});
  },
  async startTimeTariff({ commit, dispatch, rootState }, tariff_item) {
    let tariff = {
      objectID: tariff_item.objectID,
      start_time: rootState.now,
      item_amount: 0
    }
    let undo = () => commit('END_TIME_TARIFF', [tariff, false, 0]);
    commit('ADD_TIME_TARIFF', tariff);
    await dispatch('updateTimeOrder', undo, {root: true});
  },
  async endTimeTariff({ commit, dispatch, rootState, rootGetters }, tariff) {
    let endTime = Math.max(tariff.start_time, rootState.now);
    let item_amount = helpers.getTimeIntervalInMins(endTime - tariff.start_time);

    let undo;
    if (item_amount === 0) {
      undo = () => commit('ADD_TIME_TARIFF',  tariff);
    } else {
      undo = () => commit('END_TIME_TARIFF',  [tariff, false, 1]);
    }
    
    // Если завершаем тариф связанный с мероприятием, то завершаем следующий за ним и увеличиваем лимит пользователей
    if (tariff.event_id) {
      let timeOrderItems = rootGetters['timeOrder'].items;
      let next_tariff = timeOrderItems[timeOrderItems.indexOf(tariff) + 1];
      if (next_tariff) {
        await dispatch('endTimeTariff', next_tariff)
      }
    }

    commit('END_TIME_TARIFF', [tariff, endTime, item_amount]);
    
    await dispatch('updateTimeOrder', undo, {root: true});
  },
  async endActiveTimeTariff({dispatch, rootGetters }) {
    let activeTariff = rootGetters.activeTimeTariff;
    if (!activeTariff) return;
    await dispatch('endTimeTariff', activeTariff)
  },
  removeEmptyTariffItems({ commit }, timeOrder) {
    for (var i = timeOrder.items.length - 1; i >= 0; i--) {
      if (timeOrder.items[i].item_amount === 0) {
        commit('REMOVE_TIME_TARIFF', [timeOrder, i]);
      }
    }
  }
}