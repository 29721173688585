/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import App from "./App.vue";

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; //Vuesax
Vue.use(Vuesax);

// axios
import axios from "axios";
Vue.prototype.$http = axios;

// Theme Configurations
import "../themeConfig.js";

// Сервис авторизации
import auth from "./auth/authService.js";
Vue.prototype.$auth = auth;

// Helpers
import helpers from "./helpers.js";
Vue.prototype.$helpers = helpers;

// Global constants
import { globals } from "./globalConstants.js";
Vue.prototype.$globals = globals;

// ACL
import acl from "./acl/acl";

// Async computed properties
import AsyncComputed from "vue-async-computed";
Vue.use(AsyncComputed);

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store/store";

// Vuesax Admin Filters
import "./filters/filters";

// Yandex Metrika
import VueYandexMetrika from "vue-yandex-metrika";
Vue.use(VueYandexMetrika, {
  id: 66610624,
  router: router,
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    trackHash: true
  }
});

// Clipboard
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// VeeValidate
import ru from "vee-validate/dist/locale/ru";
import VeeValidate, { Validator } from "vee-validate";
Vue.use(VeeValidate);
Validator.localize("ru", ru);

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// Feather font icon
require("./assets/css/iconfont.css");

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  acl,
  render: h => h(App)
}).$mount("#app");
