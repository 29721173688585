<template>
  <vs-button
    class="vs-con-loading__container"
    ref="button"
    @click="clickMethod()"
    v-bind="$attrs"
    :disabled="$store.getters.loading || disabled"
  >
    <slot></slot>
  </vs-button>
</template>

<script>
export default {
  name: "api-button",
  props: {
    click: {
      type: Function,
      required: true
    },
    fullScreenLoader: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean
    }
  },
  methods: {
    async clickMethod() {
      if (this.fullScreenLoader) {
        this.$vs.loading();
      } else {
        this.$vs.loading({
          background: "primary",
          color: "#fff",
          container: this.$refs.button.$el,
          scale: 0.45
        });
      }

      await this.click();

      if (this.fullScreenLoader) {
        this.$vs.loading.close();
      } else {
        this.$refs.button && this.$vs.loading.close(this.$refs.button.$el);
      }
    }
  }
};
</script>
